import React from 'react'
import { Redirect } from '@reach/router'
import { NpmScratchPage } from 'src/local_modules'

import useCmsHome from '../hooks/useCmsHome'

export default function Scratch() {
  const { sections } = useCmsHome()

  const scratchBanner = sections?.find(
    ({ name }) => name === 'SCRATCH_BANNER'
  )?.data

  const hasScratchPromotion = scratchBanner?.isActive

  // redirect to 404
  if (!hasScratchPromotion) {
    const params = new URLSearchParams({
      from: encodeURIComponent('/aceitar-termos-promocao'),
    })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmScratchPage scratchBanner={scratchBanner} />
}
